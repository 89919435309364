html {
  background-color: #fffaf0;
  font-family: 'Brush Script MT', cursive;
}
.star {
  width:13px;
  height:12px;
  display:inline-block;
  background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuMzA5IDkuMjJMMi40MDkgMTJsMS40NC00LjU2N0wwIDQuNTgzbDQuNzg4LS4wNDJMNi4zMDggMCA3LjgzIDQuNTRsNC43ODkuMDQ0LTMuODUgMi44NDlMMTAuMjA5IDEyeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+)
}
body {
  margin:0 auto;
  -webkit-font-smoothing:antialiased;
  text-rendering:optimizeLegibility;
  line-height:1.6;
  font-size:1rem;
  font-family:var(--sans-font)
}
.only-print {
  display:none
}
a {
  color:#000;
  text-decoration-skip-ink:auto;
  text-decoration:underline
}
a:visited {
  color:#333
}
ul {
  margin:1rem 0
}
ul ul {
  margin:0
}
body,
h1 a {
  color:#111
}
h1,
h2 {
  margin-bottom:.5rem;
  line-height:1.25;
  font-weight:600
}
h1 {
  font-size:32px;
  letter-spacing:.004em
}
h2 {
  font-size:1.5rem;
  letter-spacing:.009em
}
.rating {
  width: 85px;
}
.limiter {
  max-width:640px;
  padding-left:20px;
  padding-right:20px;
  margin-left:auto;
  margin-right:auto
}
.pad2y {
  padding-top:20px;
  padding-bottom:20px
}
.narratives {
  display:grid;
  grid-column-gap:5px;
  grid-row-gap:5px;
  grid-template-columns:1fr .75fr min-content 70px
}
.narratives a {
  font-weight:500;
  letter-spacing:-.015em
}
.narratives>div {
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis
}
.narratives time {
  text-align: right;
  padding-right:.25em;
  padding-bottom: .4em;
  color:#333;
  font-variant-numeric:tabular-nums;
  letter-spacing:-.012em;
}
@media screen and (max-width:640px) {
  .limiter {
    width:auto
  }
  .narratives {
    grid-template-columns:1fr;
    grid-row-gap:0
  }
  .narratives div {
    white-space:normal
  }
  .narratives div:nth-child(4n) {
    padding-bottom:20px
  }
}
@media screen and (min-width:640px) {
  .nu a {
    text-decoration:none
  }
  .nu a:hover {
    text-decoration:underline
  }
}
@media screen and (max-width:1024px) {
  .header-wrap {
    border-bottom:1px solid #000;
    padding-bottom:20px
  }
}
@media screen and (min-width:1025px) {
  .header {
    position:absolute;
    top:40px;
    left:50%;
    margin-left:340px!important;
    letter-spacing:-.009em
  }
  .content h1:first-child {
    margin-top:0
  }
}
@media print {
  .no-print {
    display:none
  }
  .only-print {
    display:block
  }
  body {
    margin:0
  }
  .limiter {
    max-width:100%!important;
    margin:0!important;
    padding:0!important
  }
}
.o {
  color:#000
}
.s {
  color:#d01040
}
